import React, { useState } from "react";
import { postQnaComment } from "../../../services/qnaService";
import CommentItem from "./CommentItem";
import FileUploader from "../FileUploader";

const QnaComments = ({ answers, isQnaClosed, qnaId, onQnaClose, setQnaData, onDownload }) => {
  const [commentText, setCommentText] = useState("");
  const [fileAttachments, setFileAttachments] = useState([]);

  const handleCommentSubmit = async () => {
    if (!commentText.trim() || isQnaClosed) return;

    try {
      const updatedQna = await postQnaComment(qnaId, commentText, fileAttachments);
      setQnaData(updatedQna);
      setCommentText("");
      setFileAttachments([]);
    } catch (error) {
      alert(error.response?.data.message ?? "오류가 발생했습니다.");
      console.error("Failed to add comment:", error);
    }
  };

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-bold text-gray-200 mb-4">댓글</h2>

      {!isQnaClosed && (
        <div className="space-y-4">
          <textarea
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            placeholder="댓글을 입력하세요"
            className="w-full border border-gray-600 bg-gray-800 text-gray-200 rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            rows={4}
          />
          <FileUploader fileAttachments={fileAttachments} setFileAttachments={setFileAttachments} />
          <button onClick={handleCommentSubmit} className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition focus:ring-2 focus:ring-green-300">
            댓글 달기
          </button>
        </div>
      )}

      {localStorage.getItem("role") === "teacher" && !isQnaClosed && (
        <button onClick={onQnaClose} className="mt-6 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition focus:ring-2 focus:ring-red-400">
          질문 완료로 변경
        </button>
      )}

      <ul className="space-y-4 mt-6">
        {answers.map((answer) => (
          <CommentItem key={answer._id} qnaId={qnaId} answer={answer} onDownload={onDownload} />
        ))}
      </ul>
    </div>
  );
};

export default QnaComments;
