import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoogleLoginButton from "./GoogleLoginButton";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useAppContext } from "../contexts/AppContext";

const Navbar = () => {
  const { token, userRole, logout } = useAppContext();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const dropdownRef = useRef(null); // 드롭다운 영역 참조

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav className="bg-gray-800 p-4 shadow-lg sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center space-x-2 text-gray-200 text-2xl font-bold">
          <img className="h-8" src="/images/logo.png" alt="Logo" />
          <span>LMS</span>
        </Link>

        <div className="hidden md:flex items-center space-x-6 text-gray-200">
          {!token ? (
            <>
              <Link to="/" className="hover:text-gray-400 transition">
                홈
              </Link>
              <Link to="/login" className="hover:text-gray-400 transition">
                로그인
              </Link>
              <Link to="/signup" className="hover:text-gray-400 transition">
                회원가입
              </Link>
            </>
          ) : (
            <>
              {userRole === "teacher" ? (
                <>
                  <Link to="/" className="hover:text-gray-400 transition">
                    대시보드
                  </Link>
                  <Link to="/schedule" className="hover:text-gray-400 transition">
                    스케줄 관리
                  </Link>
                  <Link to="/courses" className="hover:text-gray-400 transition">
                    강의 관리
                  </Link>
                  <Link to="/library" className="hover:text-gray-400 transition">
                    자료실
                  </Link>
                  <Link to="/qnas" className="hover:text-gray-400 transition">
                    QnA
                  </Link>
                  <Link to="/proposals" className="hover:text-gray-400 transition">
                    제안 관리
                  </Link>
                  <Link to="/folders" className="hover:text-gray-400 transition">
                    폴더 관리
                  </Link>
                  <Link to="/payments" className="hover:text-gray-400 transition">
                    납부 내역
                  </Link>
                  <Link to="/income-report" className="hover:text-gray-400 transition">
                    소득 보고서
                  </Link>
                  <Link to="/next-month-tuition" className="hover:text-gray-400 transition">
                    다음달 예측 보고서
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/" className="hover:text-gray-400 transition">
                    대시보드
                  </Link>
                  <Link to="/schedule" className="hover:text-gray-400 transition">
                    스케줄
                  </Link>
                  <Link to="/courses" className="hover:text-gray-400 transition">
                    강의 목록
                  </Link>
                  <Link to="/library" className="hover:text-gray-400 transition">
                    자료실
                  </Link>
                  <Link to="/qnas" className="hover:text-gray-400 transition">
                    QnA
                  </Link>
                  <Link to="/proposals" className="hover:text-gray-400 transition">
                    제안 관리
                  </Link>
                  <Link to="/payments" className="hover:text-gray-400 transition">
                    납부 내역
                  </Link>
                </>
              )}

              <div className="relative" ref={dropdownRef}>
                <button onClick={toggleDropdown} className="flex items-center focus:outline-none bg-transparent hover:bg-transparent">
                  <img src="/images/avatar.png" alt="Avatar" className="w-10 h-10 rounded-full" />
                </button>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-gray-700 rounded-md shadow-lg z-10">
                    <Link to="/profile" onClick={handleCloseDropdown} className="block px-4 py-2 text-gray-200 hover:bg-gray-600">
                      내 정보 수정
                    </Link>
                    <Link to="/change-password" onClick={handleCloseDropdown} className="block px-4 py-2 text-gray-200 hover:bg-gray-600">
                      비밀번호 수정
                    </Link>
                    {userRole === "teacher" && <GoogleLoginButton />}
                    <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-gray-200 hover:bg-gray-600">
                      로그아웃
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {/* 모바일 메뉴 버튼 */}
        <div className="md:hidden">
          <button onClick={toggleDropdown} className="text-gray-200 focus:outline-none">
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 top-14 bg-gray-700 rounded-lg shadow-lg z-10 w-full max-w-xs px-4 py-4 text-gray-200">
              <div className="space-y-2">
                {!token ? (
                  <>
                    <Link to="/" className="block hover:bg-gray-600 rounded px-4 py-2">
                      홈
                    </Link>
                    <Link to="/login" className="block hover:bg-gray-600 rounded px-4 py-2">
                      로그인
                    </Link>
                    <Link to="/signup" className="block hover:bg-gray-600 rounded px-4 py-2">
                      회원가입
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/" className="block hover:bg-gray-600 rounded px-4 py-2">
                      대시보드
                    </Link>
                    <Link to="/schedule" className="block hover:bg-gray-600 rounded px-4 py-2">
                      스케줄
                    </Link>
                    <Link to="/courses" className="block hover:bg-gray-600 rounded px-4 py-2">
                      강의 목록
                    </Link>
                    <Link to="/qnas" className="block hover:bg-gray-600 rounded px-4 py-2">
                      QnA
                    </Link>
                    <Link to="/proposals" className="block hover:bg-gray-600 rounded px-4 py-2">
                      제안 관리
                    </Link>
                    <Link to="/payments" className="block hover:bg-gray-600 rounded px-4 py-2">
                      납부 내역
                    </Link>
                    {userRole === "teacher" && (
                      <>
                        <Link to="/income-report" className="block hover:bg-gray-600 rounded px-4 py-2">
                          소득 보고서
                        </Link>
                        <Link to="/next-month-tuition" className="block hover:bg-gray-600 rounded px-4 py-2">
                          다음달 예측 보고서
                        </Link>
                        <GoogleLoginButton />
                      </>
                    )}
                    <Link to="/profile" className="block hover:bg-gray-600 rounded px-4 py-2">
                      내 정보 수정
                    </Link>
                    <Link to="/change-password" className="block hover:bg-gray-600 rounded px-4 py-2">
                      비밀번호 수정
                    </Link>
                    <button onClick={handleLogout} className="w-full text-left px-4 py-2 hover:bg-gray-600 rounded">
                      로그아웃
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
