import React from "react";

const CourseSelector = ({ courseList, selectedCourse, setSelectedCourse, setStudentList }) => {
  const handleCourseChange = (e) => {
    const courseId = e.target.value;
    const selected = courseList.find((course) => course._id === courseId);
    setSelectedCourse(selected);
    if (localStorage.getItem("role") === "teacher" && selected) {
      setStudentList(selected.students);
    }
  };

  return (
    <div className="mb-6">
      <label className="block text-gray-300 font-medium mb-2">과목 선택</label>
      <select
        value={selectedCourse?._id ?? ""}
        onChange={handleCourseChange}
        className="border border-gray-600 rounded-lg p-3 w-full bg-gray-800 text-gray-100 focus:ring-2 focus:ring-blue-500 focus:outline-none transition"
      >
        <option value="">과목을 선택하세요</option>
        {courseList.map((course) => (
          <option key={course._id} value={course._id}>
            {course.courseName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CourseSelector;
