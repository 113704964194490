import React from "react";

const ClassListItem = React.memo((props) => {
  const { item, userRole, setSelected, setModalOpen } = props;
  if (!item) return null;

  const handleCreateAssignment = () => {
    setSelected((prev) => ({ ...prev, class: item }));
    setModalOpen((prev) => ({ ...prev, createAssignment: true }));
  };

  const handleViewComment = () => {
    setSelected((prev) => ({ ...prev, class: item }));
    setModalOpen((prev) => ({ ...prev, commentClass: true }));
  };

  const handleViewDetail = () => {
    setSelected((prev) => ({ ...prev, class: item }));
    setModalOpen((prev) => ({ ...prev, class: true }));
  };

  return (
    <li className="bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out border border-gray-700 transform hover:-translate-y-1 hover:scale-105">
      <div className="flex flex-col justify-between h-full">
        <div className="space-y-3">
          <p className="text-gray-400 text-lg">
            <span className="font-semibold text-blue-400">날짜: </span>
            {new Date(item.schedule.start).toLocaleDateString()}
          </p>
          <p className="text-gray-400 text-lg">
            <span className="font-semibold text-blue-400">시간: </span>
            {new Date(item.schedule.start).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} -{" "}
            {new Date(item.schedule.end).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
          </p>
        </div>
        <div className="flex space-x-3 mt-6">
          {userRole === "teacher" && (
            <button
              onClick={handleCreateAssignment}
              className="flex-1 bg-purple-600 text-white py-2 rounded-xl font-semibold shadow-lg transform hover:bg-purple-700 hover:scale-105 transition duration-200"
            >
              과제 생성
            </button>
          )}
          <button onClick={handleViewComment} className="flex-1 bg-teal-600 text-white py-2 rounded-xl font-semibold shadow-lg transform hover:bg-teal-700 hover:scale-105 transition duration-200">
            댓글 보기
          </button>
          <button onClick={handleViewDetail} className="flex-1 bg-yellow-600 text-white py-2 rounded-xl font-semibold shadow-lg transform hover:bg-yellow-700 hover:scale-105 transition duration-200">
            상세 보기
          </button>
        </div>
      </div>
    </li>
  );
});

export default ClassListItem;
