import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Modal from "../Modal";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "../../styles/markdown.css";

import "katex/dist/katex.min.css";

const MaterialViewerModal = ({ modalOpen, setModalOpen, selected, markdownContent }) => {
  return (
    <Modal isOpen={modalOpen.material} onClose={() => setModalOpen((prev) => ({ ...prev, ["material"]: false }))} width="6xl">
      <div className="p-6 max-w-6xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-y-auto max-h-[80vh] space-y-4">
        {/* <h2 className="text-3xl font-semibold text-gray-200 text-center">{selected.material?.fileName ?? selected.material.file}</h2> */}
        <div className="prose max-w-none mx-auto text-start markdown-body text-gray-200">
          <ReactMarkdown remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeKatex]}>
            {selected.material.content}
          </ReactMarkdown>
        </div>
        <button onClick={() => setModalOpen((prev) => ({ ...prev, ["material"]: false }))} className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition">
          닫기
        </button>
      </div>
    </Modal>
  );
};

export default MaterialViewerModal;
