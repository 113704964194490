import React from "react";
import Modal from "../Modal";

const ClassDetailModal = ({ modalOpen, setModalOpen, userRole, isCreateAssignment, selected, handleSaveNotes, setSelected }) => {
  return (
    <Modal isOpen={modalOpen.class} onClose={() => setModalOpen((prev) => ({ ...prev, ["class"]: false }))} width="6xl">
      <div className="p-6 max-w-6xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-y-auto max-h-[80vh] space-y-6">
        <h2 className="text-3xl font-semibold text-gray-100 text-center">수업 상세 정보</h2>
        {userRole === "teacher" ? (
          <>
            <div>
              <label className="block text-gray-300 font-medium mb-1">강의 노트</label>
              <textarea
                className="w-full border border-gray-600 bg-gray-700 text-gray-200 rounded p-2"
                defaultValue={selected.class.notes.lectureNote}
                onChange={(e) => {
                  const updatedClass = { ...selected.class };
                  updatedClass.notes.lectureNote = e.target.value;
                  setSelected((prev) => ({ ...prev, ["class"]: updatedClass }));
                }}
              />
            </div>
            <div>
              <label className="block text-gray-300 font-medium mb-1">교강사 노트</label>
              <textarea
                className="w-full border border-gray-600 bg-gray-700 text-gray-200 rounded p-2"
                defaultValue={selected.class.notes.teacherNote}
                onChange={(e) => {
                  const updatedClass = { ...selected.class };
                  updatedClass.notes.teacherNote = e.target.value;
                  setSelected((prev) => ({ ...prev, ["class"]: updatedClass }));
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <label className="block text-gray-300 font-medium mb-1">강의 노트</label>
              <p className="text-gray-200 whitespace-pre-line">{selected.class.notes.lectureNote}</p>
            </div>
            <div>
              <label className="block text-gray-300 font-medium mb-1">학생 노트</label>
              <textarea
                className="w-full border border-gray-600 bg-gray-700 text-gray-200 rounded p-2"
                defaultValue={selected.class.notes.studentNote}
                onChange={(e) => {
                  const updatedClass = { ...selected.class };
                  updatedClass.notes.studentNote = e.target.value;
                  setSelected((prev) => ({ ...prev, ["class"]: updatedClass }));
                }}
              />
            </div>
          </>
        )}
        <button onClick={handleSaveNotes} className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition">
          노트 저장
        </button>
      </div>
    </Modal>
  );
};

export default ClassDetailModal;
