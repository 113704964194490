import React, { useEffect, useState } from "react";
import axios from "axios";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { format, parse, startOfWeek, getDay, differenceInMinutes, addMinutes } from "date-fns";
import { fetchCourses } from "../../services/courseService";
import { fetchStudentSchedules, requestScheduleModification, confirmScheduleModification, cancelScheduleModification } from "../../services/scheduleService";
import { getCurrentUser } from "../../services/userService"; // 사용자 정보 가져오기 함수
import Modal from "../../components/Modal";
import ko from "date-fns/locale/ko";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

const locales = { ko };
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const DragAndDropCalendar = withDragAndDrop(Calendar);

const ScheduleStudent = () => {
  const [schedules, setSchedules] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [courses, setCourses] = useState([]); // 수강 중인 과목 목록
  const [selectedCourse, setSelectedCourse] = useState("all"); // 선택된 과목 필터
  const [selectedEvent, setSelectedEvent] = useState(null); // 선택된 이벤트
  const [modalOpen, setModalOpen] = useState(false); // 모달 상태
  const [tempEvent, setTempEvent] = useState(null); // 수정 중인 이벤트
  const [currentUser, setCurrentUser] = useState(null); // 현재 사용자 정보 저장
  const [modificationRequests, setModificationRequests] = useState([]); // 수정 요청 목록
  const [showModificationRequests, setShowModificationRequests] = useState(true); // 수정 요청 목록의 가시성 상태
  const [showTeacherSchedule, setShowTeacherSchedule] = useState(true); // 수정 요청 목록의 가시성 상태
  const [filteredSchedules, setFilteredSchedules] = useState([]); // 수정 요청 목록의 가시성 상태
  const [calendarOptions, setCalendarOptions] = useState(["month", "week", "day", "agency"]);

  // 스케줄 데이터를 가져오는 함수
  const fetchSchedules = async (selectedDate) => {
    try {
      console.log(selectedDate);
      const data = await fetchStudentSchedules(selectedDate);
      setSchedules(data);
      console.log(data.length);
      // 수정 요청 목록을 필터링
      const pendingRequests = data.filter((schedule) => schedule.modificationRequested && schedule.course);
      setModificationRequests(pendingRequests);
    } catch (error) {
      console.error("스케줄 불러오기 실패:", error);
    }
  };

  // 학생의 수강 중인 과목 목록 가져오기
  const fetchStudentCourses = async () => {
    try {
      const data = await fetchCourses();
      setCourses(data);
    } catch (error) {
      console.error("과목 목록 불러오기 실패:", error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { user } = await getCurrentUser(); // 사용자 정보를 가져옴
        setCurrentUser(user);
      } catch (error) {
        console.error("사용자 정보를 불러오는 중 오류 발생:", error);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    fetchSchedules(selectedDate); // 스케줄 가져오기
    fetchStudentCourses(); // 수강 중인 과목 가져오기
  }, [selectedDate]);

  const toggleModificationRequests = () => {
    setShowModificationRequests(!showModificationRequests);
  };

  const toggleShowTeacherSchedule = () => {
    setShowTeacherSchedule(!showTeacherSchedule);
    if (showTeacherSchedule) {
      setCalendarOptions([...calendarOptions, "agenda"]);
    } else {
      setCalendarOptions(["month", "week", "day"]);
    }
  };

  // 선택된 날짜가 변경되면 스케줄을 다시 불러옴
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // 필터링된 스케줄 목록 반환
  const getFilteredSchedules = () => {
    if (selectedCourse === "all") {
      if (!showTeacherSchedule) {
        console.log("show mine only");
        return schedules.filter((schedule) => schedule.course);
      }
      return schedules;
    }
    if (!showTeacherSchedule) {
      console.log("show mine only");
      return schedules.filter((schedule) => schedule.course && schedule?.course._id === selectedCourse);
    }
    return schedules.filter((schedule) => !schedule.course || schedule?.course._id === selectedCourse);
  };

  useEffect(() => {
    setFilteredSchedules(getFilteredSchedules());
  }, [schedules, showTeacherSchedule]);

  // 강의별 색상 구분
  const eventPropGetter = (event) => {
    let backgroundColor = event.color || "#3174ad"; // 강의별로 색상 구분
    if (event.course && event.status === "pending-modification") {
      backgroundColor = "#f39c12"; // 수정 요청 중인 일정은 오렌지색
    }
    return { style: { backgroundColor } };
  };

  // 일정 드래그 후 시간 변경
  const handleEventDrop = async ({ event, start, end }) => {
    const now = new Date();
    const eventStart = new Date(event.start);

    if (eventStart < now) {
      alert("지난 일정은 수정할 수 없습니다.");
      return; // 과거 일정은 수정 불가
    }

    if (!event.course) return; // course 정보가 없는 경우에는 수정 불가
    try {
      // 서버에 일정 수정 요청 전송
      await requestScheduleModification(event.id, start, end); // 수정된 API 호출
      alert("일정 수정 요청이 완료되었습니다.");
      fetchSchedules(selectedDate); // 스케줄 갱신
    } catch (error) {
      console.error("일정 수정 요청 실패:", error);
    }
  };

  // 이벤트 클릭 시 모달 열기
  const handleSelectEvent = (event) => {
    if (!event.course) return; // course 정보가 없는 경우에는 수정 불가
    console.log(event);
    setSelectedEvent(event);
    setTempEvent({ ...event });
    setModalOpen(true); // 모달 열기
  };

  // 수정 요청 취소 버튼 클릭 시 처리
  const cancelModificationRequest = async (id) => {
    console.log(IdleDeadline);
    try {
      await cancelScheduleModification(id); // 수정 요청 취소 API 호출
      alert("수정 요청이 취소되었습니다.");
      setModalOpen(false);
      fetchSchedules(selectedDate); // 스케줄 갱신
    } catch (error) {
      console.error("수정 취소 실패:", error);
    }
  };

  // 수정 요청 함수
  const handleModificationRequest = async (event) => {
    const now = new Date();
    const eventStart = new Date(event.start);

    if (eventStart < now) {
      alert("지난 일정은 수정할 수 없습니다.");
      return; // 과거 일정은 수정 불가
    }
    try {
      await requestScheduleModification(event.id, event.start, event.end);
      alert("수정 요청이 완료되었습니다.");
      setModalOpen(false);
      fetchSchedules(selectedDate); // 스케줄 갱신
    } catch (error) {
      console.error("수정 요청 실패:", error);
    }
  };

  const handleModificationConfirms = async (scheduleId, status) => {
    try {
      await confirmScheduleModification(scheduleId, status);
      alert(`수정 요청을 ${status ? "승인" : "거절"} 했습니다.`);
      setModalOpen(false);
      fetchSchedules(selectedDate); // 스케줄 갱신
    } catch (error) {
      console.error("수정 요청 실패:", error);
    }
  };

  // 일정 시간 수정
  const handleEventChange = (key, value) => {
    console.log(tempEvent);
    if (key === "start") {
      // 기존 이벤트의 지속 시간(duration)을 계산하여 종료 시간을 자동으로 설정
      const durationInMinutes = differenceInMinutes(new Date(tempEvent.end), new Date(tempEvent.start));
      const newEnd = addMinutes(new Date(value), durationInMinutes); // 시작 시간에 지속 시간을 더해 종료 시간 설정
      console.log(durationInMinutes);
      setTempEvent((prev) => ({ ...prev, start: value, end: newEnd }));
    } else {
      setTempEvent((prev) => ({ ...prev, [key]: value }));
    }
  };

  // 수정 요청 리스트를 화면에 표시
  const renderModificationRequests = () => {
    if (modificationRequests.length === 0) {
      return <p className="text-center text-gray-400">수정 요청이 없습니다.</p>;
    }

    return (
      <ul className="divide-y divide-gray-700">
        {modificationRequests.map((request) => {
          const isRequester = request.modifiedBy === currentUser._id;
          return (
            <li key={request._id} className="py-4 flex items-center justify-between">
              <div>
                <h3 className="text-lg font-semibold text-gray-200">{request.course ? request.course.courseName : "비공개 수업"}</h3>
                <p className="text-sm text-gray-400">
                  <del>
                    {format(new Date(request.originalStart), "yyyy-MM-dd HH:mm")} - {format(new Date(request.originalEnd), "yyyy-MM-dd HH:mm")}
                  </del>
                </p>
                <p className="text-sm text-gray-400">
                  {format(new Date(request.start), "yyyy-MM-dd HH:mm")} - {format(new Date(request.end), "yyyy-MM-dd HH:mm")}
                </p>
                <p className="text-sm text-gray-400">{isRequester ? "내가 요청함" : "상대방이 요청함"}</p>
              </div>
              <div className="flex space-x-2">
                {!isRequester && (
                  <>
                    <button onClick={() => handleModificationConfirms(request._id, true)} className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition">
                      승인
                    </button>
                    <button onClick={() => handleModificationConfirms(request._id, false)} className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition">
                      거절
                    </button>
                  </>
                )}
                {isRequester && (
                  <button onClick={() => cancelModificationRequest(request._id)} className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition">
                    수정 취소
                  </button>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  // 버튼 조건부 렌더링: 수정 요청에 따라 버튼을 다르게 표시
  const renderModificationButtons = () => {
    if (!tempEvent || !currentUser) return null;

    const isRequester = tempEvent.modifiedBy === currentUser._id; // 수정 요청자와 현재 사용자 비교
    const isRecipient = tempEvent.modifiedBy !== currentUser._id; // 수정 요청을 받은 사람

    if (tempEvent.modificationRequested) {
      if (isRequester) {
        // 수정 요청을 한 사람
        return (
          <>
            <button onClick={() => cancelModificationRequest(tempEvent.id)} className="w-full py-2 bg-red-600 text-white rounded hover:bg-red-700 transition">
              수정 취소
            </button>
            <button onClick={() => handleModificationRequest(tempEvent)} className="w-full py-2 bg-blue-600 text-white rounded hover:bg-blue-700 mt-4 transition">
              재수정
            </button>
          </>
        );
      } else if (isRecipient) {
        // 수정 요청을 받은 사람
        return (
          <>
            <button onClick={() => confirmScheduleModification(tempEvent.id, true)} className="w-full py-2 bg-green-600 text-white rounded hover:bg-green-700 transition">
              수정 승인
            </button>
            <button onClick={() => confirmScheduleModification(tempEvent.id, false)} className="w-full py-2 bg-red-600 text-white rounded hover:bg-red-700 mt-4 transition">
              수정 거절
            </button>
          </>
        );
      }
    } else {
      // 수정 요청이 없는 경우 수정 요청 버튼
      return (
        <button onClick={() => handleModificationRequest(tempEvent)} className="w-full py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition">
          수정 요청
        </button>
      );
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="mx-auto p-6 bg-gray-900">
        <h2 className="text-3xl font-bold mb-6 text-gray-200 text-white">스케줄 관리</h2>

        {/* 필터링 UI */}
        <div className="mb-4">
          <label className="block text-gray-400 font-semibold mb-2">과목별 보기</label>
          <select value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)} className="w-full p-2 border border-gray-700 bg-gray-800 text-gray-300 rounded">
            <option value="all">전체보기</option>
            {courses.map((course) => (
              <option key={course._id} value={course._id}>
                {course?.courseName || "개인 일정"}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end mb-4 space-x-2">
          <button onClick={toggleShowTeacherSchedule} className="bg-blue-600 text-white hover:bg-blue-700 font-semibold py-2 px-4 rounded-lg shadow transition duration-300 focus:outline-none">
            {showTeacherSchedule ? "내 일정만 보기" : "교강사 일정도 보기"}
          </button>
          <button onClick={toggleModificationRequests} className="bg-blue-600 text-white hover:bg-blue-700 font-semibold py-2 px-4 rounded-lg shadow transition duration-300 focus:outline-none">
            {showModificationRequests ? "수정 요청 목록 숨기기" : "수정 요청 목록 보기"}
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* 수정 요청 리스트 */}
          {showModificationRequests && (
            <div className="bg-gray-800 shadow-lg rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-4 text-gray-200">수정 요청 목록</h3>
              {renderModificationRequests()}
            </div>
          )}

          {/* 캘린더 */}
          <div className={`bg-gray-800 shadow-lg rounded-lg p-6 ${showModificationRequests ? "lg:col-span-1" : "lg:col-span-2"}`}>
            <DragAndDropCalendar
              localizer={localizer}
              events={filteredSchedules.map((schedule) => ({
                title: `${schedule.course?.courseName || "개인 일정"}`,
                start: new Date(schedule.start),
                end: new Date(schedule.end),
                originalStart: schedule.originalStart,
                originalEnd: schedule.originalEnd,
                id: schedule._id,
                status: schedule.status, // 일정 상태 추가
                color: schedule.course?.colorCode || "#ccc2d6",
                course: schedule.course,
                modifiedBy: schedule.modifiedBy,
                modificationRequested: schedule.modificationRequested,
              }))}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 800 }}
              eventPropGetter={eventPropGetter}
              views={calendarOptions}
              onNavigate={handleDateChange} // 달력 날짜 이동 시 호출
              onSelectEvent={handleSelectEvent} // 일정 클릭 시 호출
              onEventDrop={handleEventDrop} // 일정 드래그 후 호출
              draggableAccessor={() => true} // 이벤트 드래그 가능하도록 설정
            />

            {/* 모달: 일정 수정 */}
            {modalOpen && selectedEvent && (
              <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <div className="p-6 bg-gray-800 rounded-lg shadow-lg">
                  <h3 className="text-xl font-bold mb-4 text-gray-200">일정 수정</h3>
                  <h3 className="text-l font-bold mb-4 text-gray-200">{selectedEvent.course.courseName}</h3>
                  {selectedEvent.status === "pending-modification" && (
                    <>
                      <div className="mb-4">
                        <strong className="text-gray-400">기존 시작 시간:</strong>
                        <p className="text-gray-300">{new Date(selectedEvent.originalStart).toLocaleString()}</p>
                      </div>
                      <div className="mb-4">
                        <strong className="text-gray-400">기존 종료 시간:</strong>
                        <p className="text-gray-300">{new Date(selectedEvent.originalEnd).toLocaleString()}</p>
                      </div>
                    </>
                  )}
                  <div className="mb-4">
                    <label className="block text-gray-400">시작 시간</label>
                    <input
                      type="datetime-local"
                      value={format(tempEvent.start, "yyyy-MM-dd'T'HH:mm")}
                      onChange={(e) => handleEventChange("start", new Date(e.target.value))}
                      className="w-full p-2 border border-gray-700 bg-gray-800 text-gray-300 rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-400">종료 시간</label>
                    <input
                      type="datetime-local"
                      value={format(tempEvent.end, "yyyy-MM-dd'T'HH:mm")}
                      onChange={(e) => handleEventChange("end", new Date(e.target.value))}
                      className="w-full p-2 border border-gray-700 bg-gray-800 text-gray-300 rounded"
                    />
                  </div>

                  {/* 조건부 렌더링된 수정/취소/승인/거절 버튼 */}
                  {renderModificationButtons()}
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default ScheduleStudent;
