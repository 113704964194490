import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  if (totalPages <= 1) return null;

  const handlePageClick = (page) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  // 페이지 네비게이션 범위 설정
  const startPage = Math.floor((currentPage - 1) / 10) * 10 + 1;
  const endPage = Math.min(startPage + 9, totalPages);

  return (
    <div className="flex justify-center mt-6 space-x-2">
      {/* 전체 페이지의 첫 번째가 아닐 때만 "이전" */}
      {startPage > 1 && (
        <button onClick={() => handlePageClick(startPage - 1)} className="px-4 py-2 rounded-lg text-sm font-medium bg-gray-700 text-gray-200 border border-gray-600 hover:bg-gray-600">
          이전
        </button>
      )}

      {/* 현재 네비게이션 범위의 페이지 번호들 */}
      {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
        <button
          key={startPage + index}
          onClick={() => handlePageClick(startPage + index)}
          className={`px-4 py-2 rounded-lg text-sm font-medium ${
            currentPage === startPage + index ? "bg-indigo-500 text-white" : "bg-gray-700 text-gray-200 border border-gray-600 hover:bg-gray-600"
          }`}
        >
          {startPage + index}
        </button>
      ))}

      {/* 전체 페이지의 마지막이 아닐 때만 "다음" */}
      {endPage < totalPages && (
        <button onClick={() => handlePageClick(endPage + 1)} className="px-4 py-2 rounded-lg text-sm font-medium bg-gray-700 text-gray-200 border border-gray-600 hover:bg-gray-600">
          다음
        </button>
      )}
    </div>
  );
};

export default Pagination;
