import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchUpcomingClasses, fetchReceivedProposals, fetchOngoingCourses, fetchQnas, fetchOngoingAssignments } from "../../services/studentService";
import { submitAssignment } from "../../services/classService";
import { updateClassNotes } from "../../services/classService";
import Modal from "../../components/Modal";

const StudentDashboard = () => {
  const navigate = useNavigate();
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [receivedProposals, setReceivedProposals] = useState([]);
  const [ongoingCourses, setOngoingCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [qnas, setQnas] = useState([]);
  const [ongoingAssignments, setOngoingAssignments] = useState([]); // 추가된 과제 상태
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalClassOpen, setModalClassOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [modalCourseOpen, setModalCourseOpen] = useState(false);
  const [modalAssignmentOpen, setModalAssignmentOpen] = useState(false); // 추가된 과제 모달 상태
  const [selectedAssignment, setSelectedAssignment] = useState(null); // 추가된 선택된 과제 상태
  const [submittedFile, setSubmittedFile] = useState(null); // 제출할 파일 상태

  useEffect(() => {
    const fetchData = async () => {
      try {
        const upcomingClassesData = await fetchUpcomingClasses();
        const receivedProposalsData = await fetchReceivedProposals();
        const ongoingCoursesData = await fetchOngoingCourses();
        const qnasData = await fetchQnas();
        const ongoingAssignmentsData = await fetchOngoingAssignments(); // 과제 데이터 가져오기

        setUpcomingClasses(upcomingClassesData);
        setReceivedProposals(receivedProposalsData);
        setOngoingCourses(ongoingCoursesData);
        setQnas(qnasData);
        setOngoingAssignments(ongoingAssignmentsData); // 상태 업데이트
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewClassList = (courseId) => {
    navigate(`/class-list/${courseId}`); // 수업 목록 페이지로 이동
  };

  const handleOpenModalClass = (classData) => {
    setSelectedClass(classData);
    setModalClassOpen(true);
  };

  const handleCloseModalClass = () => {
    setModalClassOpen(false);
    setSelectedClass(null);
  };

  const handleOpenCourseModal = (course) => {
    setSelectedCourse(course);
    setModalCourseOpen(true);
  };

  const handleCloseCourseModal = () => {
    setModalCourseOpen(false);
    setSelectedCourse(null);
  };

  const handleOpenModalAssignment = (assignment) => {
    // 추가된 과제 모달 열기 함수
    setSelectedAssignment(assignment);
    setModalAssignmentOpen(true);
  };

  const handleCloseModalAssignment = () => {
    // 추가된 과제 모달 닫기 함수
    setModalAssignmentOpen(false);
    setSelectedAssignment(null);
  };

  const handleSaveNotes = async () => {
    const { _id } = selectedClass; // 선택된 클래스 ID 가져오기
    const teacherNote = selectedClass.notes.teacherNote; // 교강사 노트
    const lectureNote = selectedClass.notes.lectureNote; // 강의 노트
    const studentNote = selectedClass.notes.studentNote; // 학생 노트

    try {
      await updateClassNotes(_id, { teacherNote, lectureNote, studentNote }); // API 호출
      alert("노트가 저장되었습니다.");
      setUpcomingClasses(upcomingClasses.map((cls) => (cls._id === selectedClass._id ? selectedClass : cls)));
      handleCloseModalClass(); // 모달 닫기
    } catch (error) {
      console.error("노트 저장 실패:", error);
      alert("노트 저장에 실패했습니다.");
    }
  };

  const handleSubmitAssignment = async () => {
    if (!submittedFile) {
      alert("제출할 파일을 선택해주세요.");
      return;
    }

    try {
      await submitAssignment(selectedAssignment._id, submittedFile); // 과제 제출 API 호출
      alert("과제가 성공적으로 제출되었습니다.");
      handleCloseModalClass();
    } catch (error) {
      console.error("과제 제출 실패:", error);
      alert("과제 제출에 실패했습니다.");
    }
  };

  const handleFileChange = (event) => {
    setSubmittedFile(event.target.files[0]);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <p className="text-lg text-gray-300">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <p className="text-lg text-red-500">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-800 to-gray-900 py-10">
      <div className="max-w-6xl mx-auto bg-gray-800 p-8 rounded-lg shadow-lg">
        {/* 다가오는 수업 섹션 */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-gray-200">다가오는 수업</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-white">
            {upcomingClasses.length > 0 ? (
              upcomingClasses.map((cls) => (
                <div key={cls._id} className="bg-gray-700 p-4 rounded-lg shadow hover:shadow-lg transition">
                  <strong className="text-blue-400">날짜:</strong> {new Date(cls.schedule.start).toLocaleDateString()} <br />
                  <strong className="text-blue-400">시간:</strong> {new Date(cls.schedule.start).toLocaleTimeString()} <br />
                  <strong className="text-blue-400">수업명:</strong> {cls.course.courseName}
                  <div className="flex justify-end mt-4">
                    <button onClick={() => handleOpenModalClass(cls)} className="text-white bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded transition">
                      상세 보기
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-400">등록된 다가오는 수업이 없습니다.</p>
            )}
          </div>
        </section>

        {/* 진행 중인 과제 섹션 */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-gray-200">진행 중인 과제</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-white">
            {ongoingAssignments.length > 0 ? (
              ongoingAssignments.map((assignment) => (
                <div key={assignment._id} className="bg-gray-700 p-4 rounded-lg shadow hover:shadow-lg transition">
                  <strong className="text-green-400">과제명:</strong> {assignment.title} <br />
                  <strong className="text-green-400">마감일:</strong> {new Date(assignment.dueDate).toLocaleDateString()}
                  <div className="flex justify-end mt-4">
                    <button onClick={() => handleOpenModalAssignment(assignment)} className="text-white bg-green-500 hover:bg-green-600 py-2 px-4 rounded transition">
                      상세 보기
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-400">진행 중인 과제가 없습니다.</p>
            )}
          </div>
        </section>

        {/* 수업 중인 강의 섹션 */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-gray-200">수업 중인 강의</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-white">
            {ongoingCourses.length > 0 ? (
              ongoingCourses.map((course) => (
                <div key={course._id} className="bg-gray-700 p-4 rounded-lg shadow hover:shadow-lg transition">
                  <strong className="text-purple-400">강의명:</strong> {course.courseName}
                  <div className="flex justify-end mt-4 space-x-2">
                    <button onClick={() => handleViewClassList(course._id)} className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-150">
                      강의실
                    </button>

                    <button onClick={() => handleOpenCourseModal(course)} className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition duration-150">
                      상세 보기
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-400">진행 중인 강의가 없습니다.</p>
            )}
          </div>
        </section>

        {/* 제안 받은 내역 섹션 */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-gray-200">제안 받은 내역</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-white">
            {receivedProposals.length > 0 ? (
              receivedProposals.map((proposal) => (
                <div key={proposal._id} className="bg-gray-700 p-4 rounded-lg shadow hover:shadow-lg transition">
                  <strong className="text-yellow-400">강의명:</strong> {proposal.course.courseName} <br />
                  <strong className="text-yellow-400">상태:</strong> {proposal.status}
                </div>
              ))
            ) : (
              <p className="text-gray-400">받은 제안이 없습니다.</p>
            )}
          </div>
        </section>

        {/* 진행 중인 Q&A 섹션 */}
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-gray-200">진행 중인 Q&A</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-white">
            {qnas.length > 0 ? (
              qnas.map((qna) => (
                <div key={qna._id} className="bg-gray-700 p-4 rounded-lg shadow hover:shadow-lg transition">
                  <strong className="text-indigo-400">질문:</strong> {qna.question} <br />
                  <span className="text-gray-400">{qna.course.courseName}</span>
                </div>
              ))
            ) : (
              <p className="text-gray-400">진행 중인 Q&A가 없습니다.</p>
            )}
          </div>
        </section>
      </div>

      {/* 상세 모달 */}
      {modalClassOpen && selectedClass && (
        <Modal isOpen={modalClassOpen} onClose={handleCloseModalClass} width="6xl">
          <div className="p-6 max-w-6xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-y-auto max-h-[80vh]">
            <h2 className="text-2xl font-semibold mb-6 text-gray-200">수업 상세 정보</h2>
            <div className="space-y-4">
              <div>
                <strong className="text-gray-400">강의명:</strong>
                <p className="text-gray-200">{selectedClass.course.courseName}</p>
              </div>
              <div>
                <strong className="text-gray-400">강의 노트:</strong>
                <p className="text-gray-200">{selectedClass.notes.teacherNote}</p>
              </div>
              <div>
                <strong className="text-gray-400">학생 노트:</strong>
                <textarea
                  className="w-full border border-gray-600 bg-gray-700 text-gray-200 rounded p-2"
                  defaultValue={selectedClass.notes.studentNote}
                  onChange={(e) => {
                    const updatedClass = { ...selectedClass };
                    updatedClass.notes.studentNote = e.target.value;
                    setSelectedClass(updatedClass);
                  }}
                />
              </div>
              <button onClick={handleSaveNotes} className="mt-2 w-full bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition">
                노트 저장
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* 강의 상세 모달 */}
      {modalCourseOpen && selectedCourse && (
        <Modal isOpen={modalCourseOpen} onClose={handleCloseCourseModal} width="6xl">
          <div className="p-6 max-w-6xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-y-auto max-h-[80vh]">
            <h2 className="text-2xl font-semibold mb-6 text-gray-200">강의 상세 정보</h2>
            <div className="space-y-4">
              <div>
                <strong className="text-gray-400">강의명:</strong>
                <p className="text-gray-200">{selectedCourse.courseName}</p>
              </div>
              <div>
                <strong className="text-gray-400">수업 방식:</strong>
                <p className="text-gray-200">{selectedCourse.courseType}</p>
              </div>
              <div>
                <strong className="text-gray-400">비용 산정 방식:</strong>
                <p className="text-gray-200">{selectedCourse.costType === "perHour" ? `시간당 ${selectedCourse.costPerHour}` : `회당 ${selectedCourse.costPerSession}`}</p>
              </div>
              <div>
                <strong className="text-gray-400">강의 목표:</strong>
                <p className="text-gray-200 whitespace-pre-line text-start">{selectedCourse.courseGoal}</p>
              </div>

              <div>
                <strong className="text-gray-400">커리큘럼:</strong>
                <p className="text-gray-200 whitespace-pre-line text-start">{selectedCourse.curriculum}</p>
              </div>
            </div>
            <button onClick={handleCloseCourseModal} className="mt-6 w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition">
              닫기
            </button>
          </div>
        </Modal>
      )}

      {/* 과제 상세 모달 */}
      {modalAssignmentOpen && selectedAssignment && (
        <Modal isOpen={modalAssignmentOpen} onClose={handleCloseModalAssignment} width="6xl">
          <div className="p-6 max-w-6xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-y-auto max-h-[80vh]">
            <h2 className="text-2xl font-semibold mb-6 text-gray-200">과제 상세 정보</h2>
            <div className="space-y-4">
              <div>
                <strong className="text-gray-400">제목:</strong>
                <p className="text-gray-200">{selectedAssignment.title}</p>
              </div>
              <div>
                <strong className="text-gray-400">설명:</strong>
                <p className="text-gray-200 whitespace-pre-line text-start">{selectedAssignment.description}</p>
              </div>
              <div>
                <strong className="text-gray-400">마감일:</strong>
                <p className="text-gray-200">{new Date(selectedAssignment.dueDate).toLocaleDateString()}</p>
              </div>

              <div>
                <strong className="text-gray-400">제출 여부:</strong>
                <p className="text-gray-200">{selectedAssignment.submittedBy ? "제출 완료" : "미제출"}</p>
                <input type="file" onChange={handleFileChange} className="border border-gray-600 bg-gray-700 text-gray-200 rounded p-2" />
                <button onClick={handleSubmitAssignment} className="mt-2 w-full bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition">
                  과제 제출
                </button>
              </div>
            </div>
            <button onClick={handleCloseModalAssignment} className="mt-2 w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition">
              닫기
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default StudentDashboard;
