import React from "react";

const FilterSection = ({ searchQuery, setSearchQuery, status, setStatus, selectedCourse, setSelectedCourse, courseList, selectedStudent, setSelectedStudent, studentList }) => {
  return (
    <div className="filter-section grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
      {/* 검색어 입력 */}
      <input
        type="text"
        placeholder="검색어 입력"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="border border-gray-600 rounded-lg px-4 py-2 w-full bg-gray-700 text-gray-200 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-150"
      />

      {/* 상태 선택 */}
      <select
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        className="border border-gray-600 rounded-lg px-4 py-2 w-full bg-gray-700 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-150"
      >
        <option value="" className="bg-gray-700 text-gray-200">
          전체 상태
        </option>
        <option value="ongoing" className="bg-gray-700 text-gray-200">
          진행 중
        </option>
        <option value="closed" className="bg-gray-700 text-gray-200">
          완료
        </option>
      </select>

      {/* 과목 선택 */}
      <select
        value={selectedCourse?._id ?? ""}
        onChange={(e) => setSelectedCourse(e.target.value)}
        className="border border-gray-600 rounded-lg px-4 py-2 w-full bg-gray-700 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-150"
      >
        <option value="" className="bg-gray-700 text-gray-200">
          과목 선택
        </option>
        {courseList.map((course) => (
          <option key={course._id} value={course._id} className="bg-gray-700 text-gray-200">
            {course.courseName}
          </option>
        ))}
      </select>

      {/* 학생 선택 (교사일 경우에만 표시) */}
      {localStorage.getItem("role") === "teacher" && selectedCourse && (
        <select
          value={selectedStudent?._id ?? ""}
          onChange={(e) => setSelectedStudent(e.target.value)}
          className="border border-gray-600 rounded-lg px-4 py-2 w-full bg-gray-700 text-gray-200 focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-150"
        >
          <option value="" className="bg-gray-700 text-gray-200">
            학생 선택
          </option>
          {studentList.map((student) => (
            <option key={student._id} value={student._id} className="bg-gray-700 text-gray-200">
              {student.user.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default FilterSection;
