import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal"; // 모달 추가
import { calculateMinuteDifference, getHourMinute } from "../../utils/dateUtils";
import { getUserRole } from "../../utils/authUtils";
import { formatDateWithDay } from "../../utils/dateUtils";
import { fetchPayments as fetchPaymentsService, updatePayment as updatePaymentService, deletePayment as deletePaymentService } from "../../services/paymentService";
import { fetchCourses } from "../../services/courseService";
import { getCurrentUser } from "../../services/userService"; // 사용자 정보 가져오기 함수
import Pagination from "../../components/Pagination";

const PaymentList = () => {
  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null); // 선택된 납부 이력
  const [modalOpen, setModalOpen] = useState(false); // 모달 상태
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [courses, setCourses] = useState([]); // 수업 목록 상태 추가
  const [selectedCourse, setSelectedCourse] = useState(""); // 선택된 수업 ID 상태 추가
  const [showPendingOnly, setShowPendingOnly] = useState(false); // pending 상태만 보여줄지 여부
  const [startDate, setStartDate] = useState(""); // 시작 날짜
  const [endDate, setEndDate] = useState(""); // 종료 날짜
  const navigate = useNavigate();
  const userRole = getUserRole(); // 사용자 역할을 가져옴

  const fetchPayments = async (page) => {
    try {
      const data = await fetchPaymentsService(page, 15, selectedCourse, startDate, endDate, showPendingOnly ? "pending" : ""); // 페이지 번호를 인자로 전달
      setPayments(data.payments);
      setTotalPages(data.totalPages);
      console.log(data);
    } catch (error) {
      console.error("납부 내역 불러오기 실패:", error);
    }
  };

  const fetchCoursesList = async () => {
    try {
      const data = await fetchCourses(); // 모든 수업 목록 가져오기
      setCourses(data); // 수업 목록 상태 업데이트
    } catch (error) {
      console.error("수업 목록 불러오기 실패:", error);
    }
  };

  useEffect(() => {
    fetchCoursesList(); // 컴포넌트 마운트 시 수업 목록 가져오기
    fetchPayments(currentPage);
  }, [currentPage, selectedCourse, showPendingOnly, startDate, endDate]);

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { user, userRole } = await getCurrentUser(); // 사용자 정보를 가져옴
        setCurrentUser(user);
        console.log(user);
      } catch (error) {
        console.error("사용자 정보를 불러오는 중 오류 발생:", error);
      }
    };

    fetchUser();
  }, []);

  const handleOpenModal = (payment) => {
    setSelectedPayment(payment);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedPayment(null);
  };

  const handleUpdatePayment = async (paymentId, status) => {
    try {
      const data = await updatePaymentService(paymentId, { status });
      console.log(data);
      alert(data.message);
      handleCloseModal();
      fetchPayments(currentPage);
    } catch (error) {
      console.error("수정 오류:", error);
      alert(error);
    }
  };

  const handleDeletePayment = async (paymentId) => {
    try {
      const data = await deletePaymentService(paymentId);
      console.log(data);
      alert(data.message);
      handleCloseModal();
      fetchPayments(currentPage);
    } catch (error) {
      console.error("삭제 오류:", error);
      alert(error);
    }
  };

  const handleStart = (newStart) => {
    setStartDate(newStart);
    setCurrentPage(1);
  };

  const handleEnd = (newEnd) => {
    setEndDate(newEnd);
    setCurrentPage(1);
  };

  const togglePendingFilter = () => {
    setShowPendingOnly((prev) => !prev); // pending 필터 토글
  };

  const confirmOrRefusePayment = () => (
    <div className="flex space-x-4">
      <button
        onClick={() => handleUpdatePayment(selectedPayment._id, "confirmed")}
        className="flex items-center px-4 py-2 rounded transition duration-300 ease-in-out bg-green-700 text-gray-100 hover:bg-green-800"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
        </svg>
        확인
      </button>
      <button
        onClick={() => handleUpdatePayment(selectedPayment._id, "refused")}
        className="flex items-center px-4 py-2 rounded transition duration-300 ease-in-out bg-red-700 text-gray-100 hover:bg-red-800"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 9a1 1 0 011-1h3a1 1 0 110 2h-3a1 1 0 01-1-1z" clipRule="evenodd" />
          <path fillRule="evenodd" d="M4.293 10.293a1 1 0 00-1.414 0l-1 1a1 1 0 000 1.414l1 1a1 1 0 001.414-1.414L3.414 11H9a1 1 0 000-2H3.414l.879-.879a1 1 0 000-1.414z" clipRule="evenodd" />
        </svg>
        거절
      </button>
    </div>
  );

  const cancelPayment = () => (
    <button
      onClick={() => handleUpdatePayment(selectedPayment._id, "cancelled")}
      className="flex items-center px-4 py-2 rounded transition duration-300 ease-in-out bg-red-700 text-gray-100 hover:bg-red-800"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M5.5 2a1 1 0 00-1 1v3h-1a1 1 0 000 2h1v3a1 1 0 002 0v-3h3a1 1 0 000-2h-3V3a1 1 0 00-1-1z" clipRule="evenodd" />
      </svg>
      취소
    </button>
  );

  const deletePayment = () => (
    <button onClick={() => handleDeletePayment(selectedPayment._id)} className="flex items-center px-4 py-2 rounded transition duration-300 ease-in-out bg-red-900 text-gray-100 hover:bg-red-800">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1h4a1 1 0 011 1v1a1 1 0 01-1 1H4a1 1 0 01-1-1V5a1 1 0 011-1h4V3a1 1 0 011-1z" clipRule="evenodd" />
        <path d="M6 8h8l-1 10H7L6 8z" />
      </svg>
      삭제
    </button>
  );

  return (
    <div className="flex flex-col items-center bg-gray-900 text-gray-100 min-h-screen">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg mt-10 w-full max-w-7xl">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-100">납부 내역 목록</h2>

        {/* 수업 필터링 */}
        <div className="mb-4">
          <label className="block mb-2 text-gray-300">수업 선택:</label>
          <select
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 bg-gray-700 text-gray-100 border-gray-600 focus:ring-gray-500"
          >
            <option value="">모든 수업</option>
            {courses.map((course) => (
              <option key={course._id} value={course._id}>
                {course.courseName}
              </option>
            ))}
          </select>
        </div>

        {/* 검색 및 날짜 범위 필터 */}
        <div className="flex mb-4">
          <input type="date" value={startDate} onChange={(e) => handleStart(e.target.value)} className="border rounded p-2 mr-2 bg-gray-700 text-gray-100 border-gray-600" />
          <input type="date" value={endDate} onChange={(e) => handleEnd(e.target.value)} className="border rounded p-2 bg-gray-700 text-gray-100 border-gray-600" />
        </div>

        {/* Pending 필터링 버튼 */}
        <div className="mb-4 flex justify-between items-center">
          <span className="text-gray-300">Pending 상태만 보기</span>
          <label className="inline-flex items-center">
            <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-400 focus:ring-gray-500" checked={showPendingOnly} onChange={togglePendingFilter} />
          </label>
        </div>

        {payments.length === 0 ? (
          <p className="text-center text-gray-300">등록된 납부 내역이 없습니다.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto bg-gray-800 text-gray-100 border-collapse">
              <thead>
                <tr className="bg-gray-700">
                  {userRole === "teacher" && <th className="py-3 px-4 border-b text-left font-medium">학생명</th>}
                  {userRole === "student" && <th className="py-3 px-4 border-b text-left font-medium">강사명</th>}
                  <th className="py-3 px-4 border-b text-left font-medium">강의명</th>
                  <th className="py-3 px-4 border-b text-left font-medium">납부 금액</th>
                  <th className="py-3 px-4 border-b text-left font-medium">납부 일시</th>
                  <th className="py-3 px-4 border-b text-left font-medium">총 수업 횟수</th>
                  <th className="py-3 px-4 border-b text-left font-medium">총 수업 시간</th>
                  <th className="py-3 px-4 border-b text-left font-medium">상태</th>
                  <th className="py-3 px-4 border-b text-left font-medium">상세 보기</th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment) => (
                  <tr key={payment._id} className="bg-gray-800 hover:bg-gray-700">
                    {userRole === "teacher" && <td className="py-3 px-4 border-b">{payment.student.user.name}</td>}
                    {userRole === "student" && <td className="py-3 px-4 border-b">{payment.teacher.user.name}</td>}
                    <td className="py-3 px-4 border-b">{payment.course.courseName}</td>
                    <td className="py-3 px-4 border-b">{payment.amount.toLocaleString()}원</td>
                    <td className="py-3 px-4 border-b">{new Date(payment.paymentDate).toLocaleDateString()}</td>
                    <td className="py-3 px-4 border-b">{payment.relatedSchedules.length}</td>
                    <td className="py-3 px-4 border-b">{getHourMinute(payment.relatedSchedules.reduce((sum, s) => sum + calculateMinuteDifference(s.start, s.end), 0))}</td>
                    <td className="py-3 px-4 border-b">{payment.status}</td>
                    <td className="py-3 px-4 border-b">
                      <button onClick={() => handleOpenModal(payment)} className="px-3 py-1 rounded transition bg-blue-700 text-gray-100 hover:bg-blue-600">
                        상세 보기
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* 페이지네이션 버튼 추가 */}
        {totalPages > 1 && <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} className="text-gray-100" />}

        {/* 납부 내역 등록 버튼 추가 */}
        {userRole === "teacher" && (
          <div className="flex justify-end mt-6">
            <button
              onClick={() => navigate("/create-payment")} // 납부 내역 등록 페이지로 이동
              className="py-2 px-4 rounded transition bg-gray-700 text-gray-100 hover:bg-gray-600"
            >
              납부 이력 등록
            </button>
          </div>
        )}
      </div>

      {/* 모달 창 */}
      {modalOpen && selectedPayment && (
        <Modal isOpen={modalOpen} onClose={handleCloseModal} width={"7xl"}>
          <div className="p-8 max-w-7xl mx-auto rounded-lg shadow-xl bg-gray-800 text-white">
            <h2 className="text-3xl font-bold mb-6 border-b pb-4 border-gray-700">납부 상세 정보</h2>
            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-4">
                {userRole === "teacher" && (
                  <div className="flex justify-between items-center">
                    <strong>학생명:</strong>
                    <p className="font-semibold">{selectedPayment.student.user.name}</p>
                  </div>
                )}
                {userRole === "student" && (
                  <div className="flex justify-between items-center">
                    <strong>강사명:</strong>
                    <p className="font-semibold">{selectedPayment.teacher.user.name}</p>
                  </div>
                )}
                <div className="flex justify-between items-center">
                  <strong>강의명:</strong>
                  <p className="font-semibold">{selectedPayment.course.courseName}</p>
                </div>
                <div className="flex justify-between items-center">
                  <strong>입금자명:</strong>
                  <p className="font-semibold">{selectedPayment.payerName}</p>
                </div>
                <div className="flex justify-between items-center">
                  <strong>납부 금액:</strong>
                  <p className="font-semibold">{selectedPayment.amount.toLocaleString()}원</p>
                </div>
                <div className="flex justify-between items-center">
                  <strong>납부 일시:</strong>
                  <p className="font-semibold">{new Date(selectedPayment.paymentDate).toLocaleDateString()}</p>
                </div>
                <div className="flex justify-between items-center">
                  <strong>상태:</strong>
                  <p className="font-semibold">{selectedPayment.status}</p>
                </div>

                {/* Confirm or Cancel buttons based on the role */}
                <div className="mt-8 flex justify-end space-x-4">
                  {selectedPayment.status === "pending" && selectedPayment.createdBy.role === "student" && currentUser.role === "teacher" && confirmOrRefusePayment()}
                  {selectedPayment.status === "pending" && selectedPayment.createdBy.role === "teacher" && currentUser.role === "student" && confirmOrRefusePayment()}
                  {selectedPayment.status === "pending" && selectedPayment.createdBy.role === "student" && currentUser.role === "student" && cancelPayment()}
                  {selectedPayment.status === "pending" && selectedPayment.createdBy.role === "teacher" && currentUser.role === "teacher" && cancelPayment()}
                  {selectedPayment.status === "refused" && currentUser.role === "student" && deletePayment()}
                  {selectedPayment.status === "cancelled" && currentUser.role === "teacher" && deletePayment()}
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <strong>총 수업 횟수:</strong>
                  <p className="font-semibold">{selectedPayment.relatedSchedules.length}회</p>
                </div>
                <div className="flex justify-between items-center">
                  <strong>총 수업 시간:</strong>
                  <p className="font-semibold">{getHourMinute(selectedPayment.relatedSchedules.reduce((sum, s) => sum + calculateMinuteDifference(s.start, s.end), 0))}</p>
                </div>
                <div className="flex flex-col space-y-2">
                  <strong>수업 스케줄:</strong>
                  <div className="overflow-y-auto max-h-100 space-y-2">
                    {selectedPayment.relatedSchedules.map((s, index) => (
                      <p key={index} className="p-2 rounded-md bg-gray-700 text-gray-200">
                        {formatDateWithDay(s.start)} ~ {formatDateWithDay(s.end)}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <button onClick={handleCloseModal} className="mt-8 w-full py-3 px-6 rounded font-semibold transition bg-gray-700 text-gray-200 hover:bg-gray-600">
              닫기
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PaymentList;
