import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axiosInstance";
import { requestNotificationPermission } from "../../services/notificationService";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = { email, password };

    try {
      const { data } = await axios.post("/api/auth/login", userData);
      localStorage.setItem("token", data.token);
      localStorage.setItem("role", data.role);
      console.log(`requestNotificationPermission: ${data._id}`);
      await requestNotificationPermission(data._id);
      navigate("/");
      window.location.reload();
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "로그인 실패");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-gray-800 to-gray-900">
      <div className="bg-gray-700 p-10 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-100">로그인</h1>
        {errorMessage && <p className="text-red-400 text-center mb-4">{errorMessage}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-300">이메일</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-500 rounded-lg focus:outline-none focus:border-blue-400 bg-gray-800 text-gray-200 placeholder-gray-500"
              placeholder="이메일을 입력하세요"
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-300">비밀번호</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-4 py-2 border border-gray-500 rounded-lg focus:outline-none focus:border-blue-400 bg-gray-800 text-gray-200 placeholder-gray-500"
              placeholder="비밀번호를 입력하세요"
            />
          </div>
          <button type="submit" className="w-full py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 focus:ring-2 focus:ring-blue-300 focus:outline-none">
            로그인
          </button>
        </form>
        <button
          onClick={() => navigate("/")}
          className="mt-4 w-full py-3 bg-gray-600 text-gray-200 rounded-lg hover:bg-gray-700 transition duration-300 focus:ring-2 focus:ring-gray-400 focus:outline-none"
        >
          뒤로 가기
        </button>
      </div>
    </div>
  );
};

export default Login;
