import React, { useState, useEffect } from "react";
import Modal from "../Modal"; // 모달 컴포넌트 추가
import MaterialViewerModal from "./MaterialViewerModal";
import { fetchSolutionsByProblem, fetchMaterialContent } from "../../services/materialService"; // Problem에 매핑된 Solution 가져오기

// 현재 날짜와 생성 날짜 비교 함수
const isNewMaterial = (createdAt) => {
  const currentDate = new Date();
  const materialDate = new Date(createdAt);
  const diffTime = Math.abs(currentDate - materialDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays <= 3; // 자료가 3일 이내에 업로드된 경우 true 반환
};

const MaterialListItem = React.memo(({ item, handleMaterialView, handleDeleteMaterial, userRole }) => {
  const [solutionModalOpen, setSolutionModalOpen] = useState(false);
  const [answerModalOpen, setAnswerModalOpen] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [selected, setSelected] = useState({ material: null });

  const [modalOpen, setModalOpen] = useState({
    material: false,
  });

  useEffect(() => {
    if (item.category === "Problem" && solutionModalOpen) {
      const loadSolutions = async () => {
        try {
          const { data } = await fetchSolutionsByProblem(item._id);
          console.log(data);
          setSolutions(data.solutions);
        } catch (error) {
          console.error("해답 목록 로드 실패:", error);
        }
      };
      loadSolutions();
    }
  }, [item, solutionModalOpen]);

  const openSolutionModal = () => setSolutionModalOpen(true);
  const closeSolutionModal = () => setSolutionModalOpen(false);

  const openAnswerModal = async (material) => {
    const content = await fetchMaterialContent(material._id);
    setSelected((prev) => ({ ...prev, material: { ...material, content } }));
    setModalOpen((prev) => ({ ...prev, ["material"]: true }));
  };

  const isNew = isNewMaterial(item.createdAt);
  // const truncatedFileName = item.fileName.length > 35 ? `${item.fileName.slice(0, 35)}...` : item.fileName;
  const truncatedFileName = item.fileName;

  return (
    <li className="flex justify-between items-center bg-gradient-to-r from-gray-700 to-gray-800 p-4 rounded-lg shadow-md border border-gray-600 hover:bg-gradient-to-r hover:from-gray-600 hover:to-gray-700 transition duration-300 ease-in-out">
      <div className="flex items-center space-x-2 max-w-[70%]">
        <span className="text-gray-200 font-semibold break-words max-w-full overflow-hidden">{truncatedFileName}</span>
        {isNew && <span className="ml-2 px-2 py-1 text-xs font-semibold text-white bg-red-500 rounded-full">N</span>}
      </div>
      <div className="flex items-center space-x-2">
        <button
          onClick={() => handleMaterialView(item)}
          className="px-4 py-2 bg-gradient-to-r from-green-600 to-green-700 text-white font-medium rounded-lg shadow-sm hover:from-green-500 hover:to-green-600 transition duration-200 ease-in-out"
        >
          보기
        </button>
        {item.category === "Problem" && (
          <button
            onClick={openSolutionModal}
            className="px-4 py-2 bg-gradient-to-r from-purple-600 to-purple-700 text-white font-medium rounded-lg shadow-sm hover:from-purple-500 hover:to-purple-600 transition duration-200 ease-in-out"
          >
            해답 보기
          </button>
        )}
        {userRole === "teacher" && (
          <button
            onClick={() => handleDeleteMaterial(item)}
            className="px-4 py-2 bg-gradient-to-r from-red-600 to-red-700 text-white font-medium rounded-lg shadow-sm hover:from-red-500 hover:to-red-600 transition duration-200 ease-in-out"
          >
            삭제
          </button>
        )}
      </div>

      {/* Solution 목록 모달 */}
      <Modal isOpen={solutionModalOpen} onClose={closeSolutionModal} width="3xl">
        <div className="p-6 bg-gray-800 rounded-lg shadow-lg space-y-6 max-h-[80vh] overflow-y-auto">
          <h3 className="text-3xl font-semibold text-center text-gray-200 mb-6">해답 목록</h3>
          <ul className="space-y-4">
            {solutions.map((solution) => (
              <li key={solution._id} className="flex justify-between items-center bg-gray-700 p-4 rounded-lg shadow-md hover:bg-gray-600 transition duration-200 ease-in-out">
                <span className="text-lg font-medium text-gray-200">{solution.fileName}</span>
                <button onClick={() => openAnswerModal(solution)} className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-500 transition duration-200">
                  답안 보기
                </button>
              </li>
            ))}
          </ul>
          <button
            onClick={closeSolutionModal}
            className="mt-4 w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-3 rounded-lg font-semibold hover:from-blue-500 hover:to-indigo-500 transition duration-200"
          >
            닫기
          </button>
        </div>
      </Modal>

      {/* Answer 모달 */}
      {modalOpen.material && selected.material && <MaterialViewerModal modalOpen={modalOpen} setModalOpen={setModalOpen} selected={selected} />}
    </li>
  );
});

export default MaterialListItem;
