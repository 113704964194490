import React from "react";
import Modal from "../Modal";

const AssignmentDetailModal = ({ modalOpen, setModalOpen, selected, userRole, handleDownloadAssignment, handleFileChange, handleSubmitAssignment }) => {
  return (
    <Modal isOpen={modalOpen.assignment} onClose={() => setModalOpen((prev) => ({ ...prev, ["assignment"]: false }))} width="6xl">
      <div className="p-6 max-w-6xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-y-auto max-h-[80vh] space-y-6">
        <h2 className="text-3xl font-semibold text-gray-100 text-center">과제 상세 정보</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-gray-300 font-medium">제목</label>
            <p className="text-gray-200">{selected.assignment.title}</p>
          </div>
          <div>
            <label className="block text-gray-300 font-medium">설명</label>
            <p className="text-gray-200 whitespace-pre-line">{selected.assignment.description}</p>
          </div>
          <div>
            <label className="block text-gray-300 font-medium">마감일</label>
            <p className="text-gray-200">{new Date(selected.assignment.dueDate).toLocaleDateString()}</p>
          </div>
          {userRole === "teacher" && selected.assignment.submittedBy && (
            <div>
              <label className="block text-gray-300 font-medium">제출 파일</label>
              <button onClick={handleDownloadAssignment} className="mt-2 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
                다운로드
              </button>
            </div>
          )}
          {userRole === "student" && (
            <div className="space-y-2">
              <input type="file" onChange={handleFileChange} className="border border-gray-600 rounded p-2 w-full bg-gray-700 text-gray-200" />
              <button onClick={handleSubmitAssignment} className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition">
                과제 제출
              </button>
            </div>
          )}
        </div>
        <button onClick={() => setModalOpen((prev) => ({ ...prev, ["assignment"]: false }))} className="mt-2 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
          닫기
        </button>
      </div>
    </Modal>
  );
};

export default AssignmentDetailModal;
