import React from "react";

const FileUploader = ({ fileAttachments, setFileAttachments }) => {
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      alert("최대 5개의 파일만 업로드할 수 있습니다.");
      return;
    }
    const validFiles = files.filter((file) => file.size <= 5 * 1024 * 1024);
    if (validFiles.length !== files.length) {
      alert("5MB 이하의 파일만 업로드 가능합니다.");
    }
    setFileAttachments(validFiles);
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-300 font-medium mb-2">파일 첨부 (최대 5개)</label>
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        className="w-full p-2 border border-gray-600 rounded-lg bg-gray-800 text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
      />
    </div>
  );
};

export default FileUploader;
