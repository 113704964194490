import React, { useEffect, useState } from "react";
import { fetchCourses } from "../../services/courseService";
import { getCurrentUser } from "../../services/userService"; // 사용자 정보 가져오기 함수
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null); // 현재 사용자 정보 저장
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [modalCourseOpen, setModalCourseOpen] = useState(false);
  const [modalParticipantsOpen, setModalParticipantsOpen] = useState(false);

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const data = await fetchCourses();
        setCourses(data);
      } catch (error) {
        console.error("강의 목록 불러오기 실패:", error);
      }
    };

    loadCourses();

    const fetchUser = async () => {
      try {
        const { user } = await getCurrentUser(); // 사용자 정보를 가져옴
        console.log(user);
        setCurrentUser(user);
      } catch (error) {
        console.error("사용자 정보를 불러오는 중 오류 발생:", error);
      }
    };

    fetchUser();
  }, []);

  const handleOpenCourseModal = async (course) => {
    setSelectedCourse(course);
    setModalCourseOpen(true);
  };

  const handleOpenParticipantsModal = async (course) => {
    setSelectedCourse(course);
    setModalParticipantsOpen(true);
  };

  const handleCloseCourseModal = () => {
    setModalCourseOpen(false);
    setSelectedCourse(null);
  };

  const handleCloseParticipantsModal = () => {
    setModalParticipantsOpen(false);
    setSelectedCourse(null);
  };

  const handleEditCourse = (courseId) => {
    navigate(`/edit-course/${courseId}`); // 강의 수정 페이지로 이동
  };

  const handleViewClassList = (courseId) => {
    navigate(`/class-list/${courseId}`); // 수업 목록 페이지로 이동
  };

  return (
    <div className="flex flex-col items-center bg-gray-900 min-h-screen">
      <div className="bg-gray-800 text-gray-200 p-8 rounded-lg shadow-lg mt-10 w-full max-w-7xl">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-100">강의 목록</h2>

        {courses.length === 0 ? (
          <p className="text-center text-gray-400">등록된 강의가 없습니다.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto bg-gray-800 border-collapse">
              <thead>
                <tr className="bg-gray-700">
                  <th className="py-3 px-4 border-b text-left text-gray-300 font-medium">강의명</th>
                  <th className="py-3 px-4 border-b text-left text-gray-300 font-medium">교강사</th>
                  <th className="py-3 px-4 border-b text-left text-gray-300 font-medium">수업 방식</th>
                  <th className="py-3 px-4 border-b text-left text-gray-300 font-medium">수업 비용 (원)</th>
                  {currentUser?.role === "teacher" && <th className="py-3 px-4 border-b text-left text-gray-300 font-medium">인원</th>}
                  <th className="py-3 px-4 border-b text-left text-gray-300 font-medium">상세</th>
                  <th className="py-3 px-4 border-b text-left text-gray-300 font-medium">강의실</th>
                  {currentUser?.role === "teacher" && <th className="py-3 px-4 border-b text-left text-gray-300 font-medium">수정</th>}
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course._id} className="bg-gray-800 hover:bg-gray-700">
                    <td className="py-3 px-4 border-b text-gray-300">
                      <div className="flex items-center">
                        <span className="w-6 h-6 rounded-full mr-2" style={{ backgroundColor: course.colorCode }}></span>
                        {course.courseName}
                        {course.googleCalendarId && " (GC 연동 완료)"}
                      </div>
                    </td>
                    <td className="py-3 px-4 border-b text-gray-300">{course.teacher.user.name}</td>
                    <td className="py-3 px-4 border-b text-gray-300">{course.courseType === "online" ? "온라인" : "대면"}</td>
                    <td className="py-3 px-4 border-b text-gray-300">{course.costPerSession || course.costPerHour}</td>
                    {currentUser?.role === "teacher" && (
                      <td className="py-3 px-4 border-b text-gray-300">
                        <button onClick={() => handleOpenParticipantsModal(course)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600">
                          {course.students.length}
                        </button>
                      </td>
                    )}
                    <td className="py-3 px-4 border-b text-gray-300">
                      <button onClick={() => handleOpenCourseModal(course)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600">
                        상세
                      </button>
                    </td>
                    <td className="py-3 px-4 border-b text-gray-300">
                      <button onClick={() => handleViewClassList(course._id)} className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600">
                        강의실
                      </button>
                    </td>
                    {currentUser?.role === "teacher" && (
                      <td className="py-3 px-4 border-b text-gray-300">
                        <button onClick={() => handleEditCourse(course._id)} className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600">
                          수정
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* 강의 등록 버튼 추가 */}
        {currentUser?.role === "teacher" && (
          <div className="flex justify-end mt-6">
            <button onClick={() => navigate("/create-course")} className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
              강의 등록
            </button>
          </div>
        )}
      </div>

      {/* 강의 상세 모달 */}
      {modalCourseOpen && selectedCourse && (
        <Modal isOpen={modalCourseOpen} onClose={handleCloseCourseModal}>
          <div className="p-6 max-w-lg mx-auto bg-gray-800 text-gray-200 rounded-lg shadow-lg overflow-y-auto max-h-[80vh]">
            <h2 className="text-2xl font-semibold mb-6 text-gray-100">강의 상세 정보</h2>
            <div className="space-y-4">
              <div>
                <strong className="text-gray-400">강의명:</strong>
                <p className="text-gray-100">{selectedCourse.courseName}</p>
              </div>
              <div>
                <strong className="text-gray-400">수업 방식:</strong>
                <p className="text-gray-100">{selectedCourse.courseType}</p>
              </div>
              <div>
                <strong className="text-gray-400">비용 산정 방식:</strong>
                <p className="text-gray-100">{selectedCourse.costType === "perHour" ? `시간당 ${selectedCourse.costPerHour}` : `회당 ${selectedCourse.costPerSession}`}</p>
              </div>
              <div>
                <strong className="text-gray-400">강의 목표:</strong>
                <p className="text-gray-100 whitespace-pre-line text-start">{selectedCourse.courseGoal}</p>
              </div>
              <div>
                <strong className="text-gray-400">커리큘럼:</strong>
                <p className="text-gray-100 whitespace-pre-line text-start">{selectedCourse.curriculum}</p>
              </div>
            </div>
            <button onClick={handleCloseCourseModal} className="mt-6 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
              닫기
            </button>
          </div>
        </Modal>
      )}

      {/* 수강생 모달 */}
      {currentUser?.role === "teacher" && modalParticipantsOpen && selectedCourse && (
        <Modal isOpen={modalParticipantsOpen} onClose={handleCloseParticipantsModal}>
          <div className="p-6 max-w-lg mx-auto bg-gray-800 text-gray-200 rounded-lg shadow-lg overflow-y-auto max-h-[80vh]">
            <h2 className="text-xl font-bold mb-4 text-gray-100">수강생 목록</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto bg-gray-800 border-collapse">
                <thead>
                  <tr className="bg-gray-700">
                    <th className="py-3 px-4 border-b text-left text-gray-300 font-medium">학생명</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCourse.students.map((student) => (
                    <tr key={student._id} className="bg-gray-800 hover:bg-gray-700">
                      <td className="py-3 px-4 border-b text-gray-200">{student.user.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button onClick={handleCloseParticipantsModal} className="mt-4 bg-gray-600 text-white py-2 rounded hover:bg-gray-700">
              닫기
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CourseList;
